/* eslint-disable no-console */
/* global FB */
/**
 * This is your JavaScript application's entry-point.
 *
 * Webpack will look into this file to find references to
 * other source files.
 */
import $ from 'jquery';
import 'popper.js';
// import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/button';

const scrollToSelector = selector => {
  const stickyHeight = $('[data-toggle="sticky-onscroll"]')
    .first()
    .outerHeight();
  console.log(stickyHeight);
  $('body,html').animate(
    {
      scrollTop: $(selector).offset().top - stickyHeight,
    },
    800
  );
};

const mainAsync = async () => {
  $('a.trigger-goto-selector').on('click', e => {
    e.preventDefault();
    const selector = e.currentTarget.getAttribute('href');
    scrollToSelector(selector);
    if (selector === '#signup') {
      const firstTextField = $('#signup')
        .find('form')
        .first()
        .find('input[type=text]')
        .first();
      if (firstTextField) {
        firstTextField.focus();
      }
    }
  });

  $('#mapModal').on('shown.bs.modal', () => {
    $('.close', '#mapModal').trigger('focus');
  });
};

const stickyToggle = (sticky, stickyWrapper, scrollElement) => {
  const stickyHeight = sticky.outerHeight();
  const stickyTop = stickyWrapper.offset().top;
  if (scrollElement.scrollTop() >= stickyTop) {
    stickyWrapper.height(stickyHeight);
    sticky.addClass('is-sticky');
  } else {
    sticky.removeClass('is-sticky');
    stickyWrapper.height('auto');
  }
};

const setStickyComponents = () => {
  // Find all data-toggle="sticky-onscroll" elements
  $('[data-toggle="sticky-onscroll"]').each(function() {
    const sticky = $(this);
    const stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
    sticky.before(stickyWrapper);
    sticky.addClass('sticky');

    // Scroll & resize events
    $(window).on('scroll.sticky-onscroll resize.sticky-onscroll shown.bs.collapse hidden.bs.collapse', function() {
      stickyToggle(sticky, stickyWrapper, $(this));
    });

    // On page load
    stickyToggle(sticky, stickyWrapper, $(window));
  });
};

$(() => {
  mainAsync();
  setStickyComponents();

  $('a.trigger-fbshare').on('click', e => {
    e.preventDefault();
    const shareUrl = $('meta[property="og:url"]').attr('content');
    console.log(shareUrl);
    FB.ui({
      method: 'share',
      href: shareUrl,
    });
  });
});
